import React, { useEffect, useState, useCallback, useRef } from "react";
import { useFrame, useThree, invalidate, ThreeEvent } from "@react-three/fiber";
import * as THREE from "three";
import { observer } from "mobx-react-lite";
import { useFloorplannerStore } from '../../store/floorplannerStore';
import { Room, RulerLineType, WallConnectionEnd, WallConnectionStart } from "../../types/wallTypes";
import { editorStore } from "../../store/editorStore";
import { updateDragHandles } from "./updateDragHandles";
import { projectToWorld } from "./projectToWorld";
import { action, set } from "mobx";
import { dragControlHandle } from "./dragControlHandle";
import { updateAlignmentLines } from "./updateAligmentLines";
import { dragWallHandle } from "./dragWallHandle";
import { handleSize, handleWidth } from "./createMiddleDragHandle";
import { dragStartHandle } from "./dragStartHandle";
import { dragEndHandle } from "./dragEndHandle";
import { updateWallConnections } from "./updateWallConnections";
import { dragMiddleVHandle } from "./dragMiddleVHandle";
import { dragMiddleHHandle } from "./dragMiddleHHandle";
import RulerLine from "./RulerLine";
import { useDragSelectedObjects } from "../../hooks/useDragSelectedObjects";

const RulerLines = observer(() => {
  const floorplannerStore = useFloorplannerStore();
  const [rooms, setRooms] = useState<Room[]>([]); // Explicitly set the type to Room[]
  const { camera, gl } = useThree();
  const dragLine = useRef<RulerLineType | undefined>();
  const dragOffset = useRef<[number, number, number, number] | null>(null);
  const dragHandle = useRef<{ handle: string; startPosition: THREE.Vector3; endPosition: THREE.Vector3 }>({
    handle: "",
    startPosition: new THREE.Vector3(),
    endPosition: new THREE.Vector3(),
  });
  const [prevCameraZoom, setPrevCameraZoom] = useState(camera.zoom);
  const [hoveredLine, setHoveredLine] = useState<string | null>(null);
  const [hoveredHandle, setHoveredHandle] = useState<string | null>(null);
  const isHovered = (handle: string) => hoveredHandle === handle;
  const [dragEndFreeToConnect, setDragEndFreeToConnect] = useState(false);
  const noopRaycast = () => null;
  const wallMinMidHandleSize = editorStore.zoomLevel < 3 ? Math.max(0.15, 0.15 / editorStore.zoomLevelDivisor()) : 0.1; // Minimum size of the mid-handle
  const wallMidHandleGap = 0.09 / editorStore.zoomLevelDivisor(); // Gap between the mid-handles
  const aboutToModify = useRef(false);
  const { startDraggingGroupSelection, isDraggingGroupSelection } = useDragSelectedObjects(gl, camera);

  useFrame(() => {
    if (camera.zoom !== prevCameraZoom) {
      setPrevCameraZoom(camera.zoom);
      invalidate();
    }
  });

  const handlePointerMove = useCallback(
    action((e: PointerEvent) => {
      if (dragLine.current !== undefined && dragOffset.current && dragOffset.current && editorStore.selections.length === 1) {
        if (aboutToModify.current) {
          aboutToModify.current = false;
          floorplannerStore.pushToUndoStack();
        }
        if (editorStore.rulerDragging !== dragLine.current.id) editorStore.setRulerDragging(dragLine.current.id);
        let [newX, newY] = projectToWorld(e.clientX, e.clientY, gl, camera);
        const [startOffsetX, startOffsetY, endOffsetX, endOffsetY] =
          dragOffset.current;
        const ruler = dragLine.current;
        if (!ruler) return;

        //Update alignment lines based on the handle type
        updateAlignmentLines(
          ruler.id,
          newX,
          newY,
          dragHandle.current.handle,
        );

        let delta = new THREE.Vector2(0, 0);
        let snapped = false;
        if (dragHandle.current.handle === "start") {
          snapped = dragStartHandle(ruler, startOffsetX, startOffsetY, newX, newY, dragEndFreeToConnect, delta);
        } else if (dragHandle.current.handle === "end") {
          snapped = dragEndHandle(ruler, endOffsetX, endOffsetY, newX, newY, dragEndFreeToConnect, delta);
        } else if (dragHandle.current.handle === "middleV") {
          snapped = dragMiddleVHandle(ruler, startOffsetX, startOffsetY, newX, newY, delta);
        } else if (dragHandle.current.handle === "middleH") {
          snapped = dragMiddleHHandle(ruler, startOffsetX, startOffsetY, newX, newY, delta);
        } else if (dragHandle.current.handle === "wall") {
          snapped = dragWallHandle(ruler, startOffsetX, startOffsetY, endOffsetX, endOffsetY, newX, newY, delta);
        } else if (dragHandle.current.handle === "control") {
          dragControlHandle(ruler, newX, newY);
        }
        updateDragHandles(dragHandle.current?.handle || "", ruler, dragHandle);

        //Update alignment lines based on the handle type
        updateAlignmentLines(
          ruler.id,
          newX,
          newY,
          dragHandle.current.handle,
        );

        if (!delta.equals(new THREE.Vector2(0, 0))) {
          updateWallConnections(
            ruler.id,
            dragHandle.current?.handle || null,
            [ruler.id],
            floorplannerStore
          );
        }
      }
    }),
    [editorStore.rulerDragging, projectToWorld, updateDragHandles, camera.zoom],
  );

  const handlePointerUp = useCallback(() => {
    aboutToModify.current = false;
    dragLine.current = undefined;
    dragOffset.current = null;
    dragHandle.current.handle = "";
    editorStore.setRulerDragging(null);
    // Change cursor back to auto when dragging is done
    document.body.classList.remove("cursor-vector")
    gl.domElement.removeEventListener("pointermove", handlePointerMove);
    gl.domElement.removeEventListener("pointerup", handlePointerUp);
    floorplannerStore.setBlockDirty(false);
    if (editorStore.rulerDragging) floorplannerStore.setDirty();
    editorStore.setWhichEndToUpdate(undefined);
  }, [gl.domElement, handlePointerMove]);


  // Define the callback functions with appropriate types
  const onLinePointerDown = useCallback((event: ThreeEvent<PointerEvent>, id: string) => {
    if (editorStore.rulerDragging || editorStore.wallConstructionMode || editorStore.lineConstructionMode || editorStore.rulerConstructionMode  || editorStore.areaConstructionMode) return;
    event.stopPropagation();
    if (editorStore.selections.length > 1) {
      startDraggingGroupSelection(event);
    }
    // Change cursor to grabbing when dragging a wall
    document.body.classList.add("cursor-vector")
    const ruler = floorplannerStore.rulerLinesMap.get(id);
    if (!ruler) return;
    aboutToModify.current = true;
    dragLine.current = ruler;
    updateDragHandles(dragHandle.current?.handle || "wall", ruler, dragHandle);
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const startOffsetX = worldX - ruler.start.x;
    const startOffsetY = worldY - ruler.start.y;
    const endOffsetX = worldX - ruler.end.x;
    const endOffsetY = worldY - ruler.end.y;
    dragOffset.current = [startOffsetX, startOffsetY, endOffsetX, endOffsetY];
    if (!editorStore.isShiftPressed && editorStore.selections.find((s) => s.id === ruler.id) === undefined) {
      editorStore.clearSelections();
    }
    editorStore.addSelection(ruler);
    floorplannerStore.selectRuler(ruler.id);

    floorplannerStore.setBlockDirty(true);
    gl.domElement.addEventListener("pointermove", handlePointerMove);
    gl.domElement.addEventListener("pointerup", handlePointerUp);
    floorplannerStore.setBlockDirty(true);

  }, []);

  const onLinePointerEnter = useCallback((event: ThreeEvent<PointerEvent>, id: string) => {
    if (editorStore.rulerDragging || editorStore.wallConstructionMode || editorStore.lineConstructionMode || editorStore.rulerConstructionMode || editorStore.areaConstructionMode) return;
    event.stopPropagation();
    // Change cursor to grab when hovering over the ruler
    if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move")  document.body.classList.add("cursor-vector")
    setHoveredLine(id);
  }, []);

  const onLinePointerLeave = useCallback((event: ThreeEvent<PointerEvent>, id: string) => {
    if (editorStore.rulerDragging || editorStore.wallConstructionMode || editorStore.lineConstructionMode || editorStore.rulerConstructionMode  || editorStore.areaConstructionMode) return;
    // Reset cursor when leaving the ruler
    if (!editorStore.rulerDragging)  document.body.classList.remove("cursor-vector")
    setHoveredLine(null);
  }, []);

  const handleKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement>, ruler: RulerLineType) => {
    // Implementation for key press handling
    if (event.key === "Enter") {
      // Implementation for handling Enter key press
    }
  }, []);

  const handleLengthClick = useCallback((lineId: string) => {
    // Implementation for handling length click
  }, []);

  const onLineSelectionPointerDown = useCallback(
    (
      event: ThreeEvent<PointerEvent>,
      ruler: RulerLineType,
      handle: "start" | "end" | "middleV" | "middleH" | "control",
    ) => {
      event.stopPropagation();
      aboutToModify.current = true;
      dragLine.current = ruler;
      updateDragHandles(handle, ruler, dragHandle);
      const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
      let offsetX, offsetY;

      if (handle === "middleV" || handle === "middleH" || handle === "control") {
        const midpointX = (ruler.start.x + ruler.end.x) / 2;
        const midpointY = (ruler.start.y + ruler.end.y) / 2;
        offsetX = worldX - midpointX;
        offsetY = worldY - midpointY;
      } else {
        offsetX = worldX - (handle === "start" ? ruler.start.x : ruler.end.x);
        offsetY = worldY - (handle === "start" ? ruler.start.y : ruler.end.y);
      }
      if (handle === "start" || handle === "end") {
        const isWallEdgeConnected = ruler.connections?.find(
          (c) => (c.targetPosition === WallConnectionStart || c.targetPosition === WallConnectionEnd) &&
            c.sourcePosition === (handle === 'start' ? WallConnectionStart : WallConnectionEnd),
        );
        setDragEndFreeToConnect(!isWallEdgeConnected);
      }

      dragOffset.current = [offsetX, offsetY, offsetX, offsetY];
      if (!editorStore.isShiftPressed && editorStore.selections.find((s) => s.id === ruler.id) === undefined) {
        editorStore.clearSelections();
      }
      editorStore.addSelection(ruler);
      floorplannerStore.selectRuler(ruler.id);
      gl.domElement.addEventListener("pointermove", handlePointerMove);
      gl.domElement.addEventListener("pointerup", handlePointerUp);
      floorplannerStore.setBlockDirty(true);
    },
    [
      floorplannerStore.rulerLinesMap,
      updateDragHandles,
      projectToWorld,
      gl.domElement,
      handlePointerMove,
      handlePointerUp,
      camera.zoom
    ],
  );

  return (
    <group>
      {floorplannerStore.rulerLinesMap.size > 0 &&
        Array.from(floorplannerStore.rulerLinesMap.values()).map((ruler) => (
          <group
            key={`group-ruler-${ruler.id}`}
          >
            <RulerLine
              key={`ruler-${ruler.id}`}
              ruler={ruler}
              color={(hoveredLine === ruler.id || ruler.selected) ?
                ruler.hoverColor?.toString() ?? "blue" :
                ruler.lineColor ?
                  ruler.lineColor.toString() :
                  "black"}
              onLinePointerDown={onLinePointerDown}
              onLinePointerEnter={onLinePointerEnter}
              onLinePointerLeave={onLinePointerLeave}
              handleKeyPress={handleKeyPress}
              handleLengthClick={handleLengthClick}
            />
            {(ruler.selected && !editorStore.wallConstructionMode && !editorStore.lineConstructionMode && !editorStore.rulerConstructionMode && !editorStore.areaConstructionMode && editorStore.selections.length === 1) && (
              <group>
                {(editorStore.rulerDragging !== ruler.id || dragHandle.current?.handle === "start") && (
                  <group
                    position={new THREE.Vector3(ruler.start.x, ruler.start.y, 0.02)}
                    onPointerDown={(event) => onLineSelectionPointerDown(event, ruler, "start")}
                    onPointerEnter={(event) => {
                      event.stopPropagation();
                      document.body.style.cursor = "move";
                      setHoveredHandle("start")
                    }}
                    onPointerLeave={(event) => {
                      event.stopPropagation();
                      if (editorStore.rulerDragging !== ruler.id) document.body.style.cursor = "auto";
                      setHoveredHandle(null)
                    }}
                  >
                    <mesh>
                      <planeGeometry args={[(handleWidth * 4) / editorStore.zoomLevelDivisor(), (handleWidth * 4) / editorStore.zoomLevelDivisor()]} />
                      <meshBasicMaterial
                        //color="white"
                        transparent
                        opacity={0.0}
                      />
                    </mesh>
                    <mesh raycast={noopRaycast}>
                      <torusGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0.003, 16, 100]} />
                      <meshBasicMaterial color="blue" />
                    </mesh>
                    <mesh
                      position={[0, 0, 0]}
                      rotation={[Math.PI / 2, 0, 0]}
                      raycast={noopRaycast}
                    >
                      <cylinderGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0, 0, 32]} />
                      <meshBasicMaterial color={isHovered("start") || dragHandle.current?.handle === "start" ? "blue" : "white"} />
                    </mesh>
                  </group>
                )}
                {(editorStore.rulerDragging !== ruler.id || dragHandle.current?.handle === "end") && (
                  <group
                    position={new THREE.Vector3(ruler.end.x, ruler.end.y, 0.02)}
                    onPointerDown={(event) => onLineSelectionPointerDown(event, ruler, "end")}
                    onPointerEnter={(event) => {
                      event.stopPropagation();
                      document.body.style.cursor = "move";
                      setHoveredHandle("end")
                    }}
                    onPointerLeave={(event) => {
                      event.stopPropagation();
                      if (editorStore.rulerDragging !== ruler.id) document.body.style.cursor = "auto";
                      setHoveredHandle(null)
                    }}
                  >
                    <mesh>
                      <planeGeometry args={[(handleWidth * 4) / editorStore.zoomLevelDivisor(), (handleWidth * 4) / editorStore.zoomLevelDivisor()]} />
                      <meshBasicMaterial
                        //color="white"
                        transparent
                        opacity={0.0}
                      />
                    </mesh>
                    <mesh raycast={noopRaycast}>
                      <torusGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0.003, 16, 100]} />
                      <meshBasicMaterial color="blue" />
                    </mesh>
                    <mesh
                      position={[0, 0, 0]}
                      rotation={[Math.PI / 2, 0, 0]}
                      raycast={noopRaycast}
                    >
                      <cylinderGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0, 0, 32]} />
                      <meshBasicMaterial color={isHovered("end") || dragHandle.current?.handle === "end" ? "blue" : "white"} />
                    </mesh>
                  </group>
                )}
              </group>
            )}
          </group>
        ))
      }
    </group>
  );
});

export default RulerLines;
