// src/components/NotificationBar.tsx

import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { RiCloseLargeLine } from "react-icons/ri";

interface NotificationBarProps {
  isVisible: boolean;
  onClose: () => void;
}

const NotificationBar: React.FC<NotificationBarProps> = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="absolute top-0 left-1/2 bg-[#110313] w-full h-[22px] px-4 py-2 text-[#fcfcfc] transform -translate-x-1/2 rounded-md border border-gray-300 shadow-md flex items-center justify-center">
      <div className="text-[11px] font-normal font-['DM Sans'] leading-none">
        You are in floorplan mode. To go back to My page, click the Boni icon in the left-hand menu.
      </div>
      <IoCloseOutline 
        className="w-5 h-5 cursor-pointer pt-1 rounded-sm"
        onClick={onClose}
        aria-label="Close notification"
      />
    </div>
  );
};

export default NotificationBar;
