import React, { useEffect, useRef, useState } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { Line } from '@react-three/drei';
import { observer } from 'mobx-react-lite';
import { editorStore } from '../../store/editorStore';
import { floorplannerStore } from '../../store/floorplannerStore';
import { isRulerLineType, isSingleLineType, isSymbolType, isWallType } from '../../types/wallTypes';

export const MAX_PLANE_SIZE = 300;

const SelectionPlane: React.FC = observer(() => {
    const { camera, gl } = useThree();
    const isSelecting = useRef(false);
    const startPoint = useRef<THREE.Vector3 | null>(null);
    const [endPoint, setEndPoint] = useState<THREE.Vector3 | null>(null);
    const initialMousePosition = useRef<{ x: number; y: number } | null>(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [selectionRectanglePoints, setSelectionRectanglePoints] = useState<THREE.Vector3[]>([]);
    const noopRaycast = () => null;

    const handlePointerDown = (event: PointerEvent) => {
        initialMousePosition.current = { x: event.clientX, y: event.clientY };
        if (
            !editorStore.view3D &&
            !editorStore.wallConstructionMode &&
            !editorStore.lineConstructionMode &&
            !editorStore.rulerConstructionMode &&
            !editorStore.areaConstructionMode &&
            !editorStore.panning
        ) {
            isSelecting.current = true;
            startPoint.current = getWorldPositionFromMouse(event.clientX, event.clientY);
            setSelectionRectanglePoints([]);
        }
    };

    const handlePointerMove = (event: PointerEvent) => {
        // If the user is selecting and not panning, update the selection rectangle
        if (isSelecting.current && startPoint.current && !editorStore.panning && event.buttons !== 0) {
            const deltaX = Math.abs(event.clientX - initialMousePosition.current!.x);
            const deltaY = Math.abs(event.clientY - initialMousePosition.current!.y);

            if (deltaX > 2 || deltaY > 2) {
                // Threshold to determine if the user is dragging
                setIsDrawing(true);
                const currentEndPoint = getWorldPositionFromMouse(event.clientX, event.clientY);

                if (currentEndPoint) {
                    setEndPoint(currentEndPoint);

                    const points = [
                        new THREE.Vector3(startPoint.current.x, startPoint.current.y, 0),
                        new THREE.Vector3(currentEndPoint.x, startPoint.current.y, 0),
                        new THREE.Vector3(currentEndPoint.x, currentEndPoint.y, 0),
                        new THREE.Vector3(startPoint.current.x, currentEndPoint.y, 0),
                        new THREE.Vector3(startPoint.current.x, startPoint.current.y, 0), // Closing the loop
                    ];

                    setSelectionRectanglePoints(points);
                }
            }
        }
    };

    const handlePointerUp = (event: PointerEvent) => {
        // If it is a click (no large movement), clear the selection
        if (initialMousePosition.current) {
            const deltaX = Math.abs(event.clientX - initialMousePosition.current.x);
            const deltaY = Math.abs(event.clientY - initialMousePosition.current.y);

            if (deltaX < 2 && deltaY < 2) {
                editorStore.clearSelections();
            }
        }
        // If the user was selecting, perform the selection
        if (isSelecting.current && !editorStore.panning) {
            isSelecting.current = false;

            if (isDrawing && startPoint.current && endPoint) {
                // Perform selection
                selectObjectsInBox(startPoint.current, endPoint);
            } else {
                // Clear selection on single click
                editorStore.clearSelections();
            }

            // Reset variables
            setIsDrawing(false);
            initialMousePosition.current = null;
            startPoint.current = null;
            setEndPoint(null);
            setSelectionRectanglePoints([]);
        }
    };

    const getWorldPositionFromMouse = (clientX: number, clientY: number): THREE.Vector3 | null => {
        const rect = gl.domElement.getBoundingClientRect();
        const ndcX = ((clientX - rect.left) / rect.width) * 2 - 1;
        const ndcY = -((clientY - rect.top) / rect.height) * 2 + 1;

        const vector = new THREE.Vector3(ndcX, ndcY, 0.5);
        vector.unproject(camera);

        const dir = vector.sub(camera.position).normalize();
        const distance = -camera.position.z / dir.z;
        const position = camera.position.clone().add(dir.multiplyScalar(distance));

        return position;
    };

    const projectToScreen = (vector: THREE.Vector3): THREE.Vector2 => {
        const projected = vector.clone().project(camera);
        const rect = gl.domElement.getBoundingClientRect();

        return new THREE.Vector2(
            (projected.x + 1) * 0.5 * rect.width + rect.left,
            (1 - projected.y) * 0.5 * rect.height + rect.top // Corrected Y calculation
        );
    };

    const selectObjectsInBox = (start: THREE.Vector3, end: THREE.Vector3) => {
        const walls = floorplannerStore.wallsMap;
        const symbols = floorplannerStore.symbolsMap;

        const screenStart = projectToScreen(start);
        const screenEnd = projectToScreen(end);

        const minX = Math.min(screenStart.x, screenEnd.x);
        const maxX = Math.max(screenStart.x, screenEnd.x);
        const minY = Math.min(screenStart.y, screenEnd.y);
        const maxY = Math.max(screenStart.y, screenEnd.y);

        // Clear previous selection
        editorStore.clearSelections();

        // Create selection rectangle in screen space
        const selectionRect = new THREE.Box2(
            new THREE.Vector2(minX, minY),
            new THREE.Vector2(maxX, maxY)
        );

        // Flag to check if any objects are selected
        let objectsSelected = false;

        Array.from(symbols.values()).forEach((symbol) => {
            if (!symbol) return;
            const groupRef = editorStore.groupRefsMap.get(symbol.id);
            if (!groupRef) {
                console.log(`Missing groupRef for symbol ${symbol.id} ${symbol.type}`);
                return;
            }
            if (groupRef) {
                groupRef.updateMatrixWorld(true); // Ensure matrixWorld is up-to-date

                // Get the bounding box
                const boundingBox = new THREE.Box3().setFromObject(groupRef);

                // Project bounding box corners to screen space
                const corners = [
                    new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z),
                    new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z),
                    new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z),
                    new THREE.Vector3(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z),
                    new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z),
                    new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z),
                    new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z),
                    new THREE.Vector3(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z),
                ];

                const symbolScreenBB = new THREE.Box2();

                corners.forEach((corner) => {
                    // No need to apply matrix again as boundingBox is already in world coordinates
                    const screenPosition = projectToScreen(corner);
                    symbolScreenBB.expandByPoint(screenPosition);
                });

                // Check if symbol's screen-space bounding box intersects the selection rectangle
                if (symbolScreenBB.intersectsBox(selectionRect)) {
                    floorplannerStore.selectSymbol(symbol.id);
                    editorStore.addSelection(symbol);
                    objectsSelected = true;
                }
            }
        });

        // For walls
        //Object.values(walls).forEach((wall) => {
        Array.from(walls.values()).forEach((wall) => {
            const wallStart = new THREE.Vector3(wall.start.x, wall.start.y, 0);
            const wallEnd = new THREE.Vector3(wall.end.x, wall.end.y, 0);

            const startScreenPos = projectToScreen(wallStart);
            const endScreenPos = projectToScreen(wallEnd);

            if (
                (startScreenPos.x >= minX &&
                    startScreenPos.x <= maxX &&
                    startScreenPos.y >= minY &&
                    startScreenPos.y <= maxY) ||
                (endScreenPos.x >= minX &&
                    endScreenPos.x <= maxX &&
                    endScreenPos.y >= minY &&
                    endScreenPos.y <= maxY)
            ) {
                floorplannerStore.selectWall(wall.id);
                editorStore.addSelection(wall);
                objectsSelected = true;
            }
        });

        // For lines
        Array.from(floorplannerStore.singleLinesMap.values()).forEach((line) => {
            const lineStart = new THREE.Vector3(line.start.x, line.start.y, 0);
            const lineEnd = new THREE.Vector3(line.end.x, line.end.y, 0);

            const startScreenPos = projectToScreen(lineStart);
            const endScreenPos = projectToScreen(lineEnd);

            if (
                (startScreenPos.x >= minX &&
                    startScreenPos.x <= maxX &&
                    startScreenPos.y >= minY &&
                    startScreenPos.y <= maxY) ||
                (endScreenPos.x >= minX &&
                    endScreenPos.x <= maxX &&
                    endScreenPos.y >= minY &&
                    endScreenPos.y <= maxY)
            ) {
                floorplannerStore.selectLine(line.id);
                editorStore.addSelection(line);
                objectsSelected = true;
            }
        });
        // For Ruler
        Array.from(floorplannerStore.rulerLinesMap.values()).forEach((line) => {
            const lineStart = new THREE.Vector3(line.start.x, line.start.y, 0);
            const lineEnd = new THREE.Vector3(line.end.x, line.end.y, 0);

            const startScreenPos = projectToScreen(lineStart);
            const endScreenPos = projectToScreen(lineEnd);

            if (
                (startScreenPos.x >= minX &&
                    startScreenPos.x <= maxX &&
                    startScreenPos.y >= minY &&
                    startScreenPos.y <= maxY) ||
                (endScreenPos.x >= minX &&
                    endScreenPos.x <= maxX &&
                    endScreenPos.y >= minY &&
                    endScreenPos.y <= maxY)
            ) {
                floorplannerStore.selectRuler(line.id);
                editorStore.addSelection(line);
                objectsSelected = true;
            }
        });
    };

    useEffect(() => {
        if (editorStore.selections.length === 0) {
            setSelectionRectanglePoints([]);
        }
    }, [editorStore.selections]);

    // Compute the bounding box around selected items in the render function
    let boundingBoxPoints: THREE.Vector3[] = [];

    if (!isDrawing && editorStore.selections.length > 1) {
        let selectionBounds = {
            minX: Number.POSITIVE_INFINITY,
            minY: Number.POSITIVE_INFINITY,
            maxX: Number.NEGATIVE_INFINITY,
            maxY: Number.NEGATIVE_INFINITY,
        };

        editorStore.selections.forEach((object) => {
            if (isSymbolType(object)) {
                //const groupRef = object.groupRef as THREE.Group | undefined;
                const groupRef = editorStore.groupRefsMap.get(object.id);

                if (groupRef) {
                    groupRef.updateMatrixWorld(true);

                    const boundingBox = new THREE.Box3().setFromObject(groupRef);


                    selectionBounds.minX = Math.min(selectionBounds.minX, boundingBox.min.x);
                    selectionBounds.minY = Math.min(selectionBounds.minY, boundingBox.min.y);
                    selectionBounds.maxX = Math.max(selectionBounds.maxX, boundingBox.max.x);
                    selectionBounds.maxY = Math.max(selectionBounds.maxY, boundingBox.max.y);
                }
            } else if (isWallType(object)) {
                const wall = floorplannerStore.wallsMap.get(object.id);
                if (wall) {
                    const wallStart = new THREE.Vector2(wall.start.x, wall.start.y);
                    const wallEnd = new THREE.Vector2(wall.end.x, wall.end.y);

                    selectionBounds.minX = Math.min(selectionBounds.minX, wallStart.x, wallEnd.x);
                    selectionBounds.minY = Math.min(selectionBounds.minY, wallStart.y, wallEnd.y);
                    selectionBounds.maxX = Math.max(selectionBounds.maxX, wallStart.x, wallEnd.x);
                    selectionBounds.maxY = Math.max(selectionBounds.maxY, wallStart.y, wallEnd.y);
                }
            } else if (isSingleLineType(object)) {
                const line = floorplannerStore.singleLinesMap.get(object.id);
                if (line) {
                    const lineStart = new THREE.Vector2(line.start.x, line.start.y);
                    const lineEnd = new THREE.Vector2(line.end.x, line.end.y);

                    selectionBounds.minX = Math.min(selectionBounds.minX, lineStart.x, lineEnd.x);
                    selectionBounds.minY = Math.min(selectionBounds.minY, lineStart.y, lineEnd.y);
                    selectionBounds.maxX = Math.max(selectionBounds.maxX, lineStart.x, lineEnd.x);
                    selectionBounds.maxY = Math.max(selectionBounds.maxY, lineStart.y, lineEnd.y);
                }
            } else if (isRulerLineType(object)) {
                const line = floorplannerStore.rulerLinesMap.get(object.id);
                if (line) {
                    const lineStart = new THREE.Vector2(line.start.x, line.start.y);
                    const lineEnd = new THREE.Vector2(line.end.x, line.end.y);

                    selectionBounds.minX = Math.min(selectionBounds.minX, lineStart.x, lineEnd.x);
                    selectionBounds.minY = Math.min(selectionBounds.minY, lineStart.y, lineEnd.y);
                    selectionBounds.maxX = Math.max(selectionBounds.maxX, lineStart.x, lineEnd.x);
                    selectionBounds.maxY = Math.max(selectionBounds.maxY, lineStart.y, lineEnd.y);
                }
            }
            // Add other object types if necessary
        });

        boundingBoxPoints = [
            new THREE.Vector3(selectionBounds.minX, selectionBounds.minY, 0),
            new THREE.Vector3(selectionBounds.maxX, selectionBounds.minY, 0),
            new THREE.Vector3(selectionBounds.maxX, selectionBounds.maxY, 0),
            new THREE.Vector3(selectionBounds.minX, selectionBounds.maxY, 0),
            new THREE.Vector3(selectionBounds.minX, selectionBounds.minY, 0), // Closing the loop
        ];
    }

    return (
        <>
            <mesh
                position={[0, 0, -0.01]} // Ensure the plane is in the background
                onPointerDown={(e) => handlePointerDown(e.nativeEvent)}
                onPointerMove={(e) => handlePointerMove(e.nativeEvent)}
                onPointerUp={(e) => handlePointerUp(e.nativeEvent)}
                userData={{ id: 'selection-plane' }}
            >
                <planeGeometry args={[MAX_PLANE_SIZE * 1.5, MAX_PLANE_SIZE * 1.5]} />
                <meshBasicMaterial transparent opacity={0} />
            </mesh>

            {/* Render transparent blue selection box while drawing */}
            {isDrawing && selectionRectanglePoints.length > 0 && (
                <mesh
                    position={[
                        (startPoint.current!.x + endPoint!.x) / 2,
                        (startPoint.current!.y + endPoint!.y) / 2,
                        0.02,
                    ]}
                    geometry={new THREE.PlaneGeometry(
                        Math.abs(endPoint!.x - startPoint.current!.x),
                        Math.abs(endPoint!.y - startPoint.current!.y)
                    )}
                >
                    <meshBasicMaterial color="blue" transparent opacity={0.2} />
                </mesh>
            )}

            {/* Render the selection rectangle outline while drawing */}
            {isDrawing && selectionRectanglePoints.length > 0 && (
                <Line
                    points={selectionRectanglePoints}
                    color="darkblue"
                    lineWidth={1}
                    raycast={noopRaycast}
                />
            )}

            {/* Render the final bounding box around selected items */}
            {boundingBoxPoints.length > 0 && (
                <Line
                    points={boundingBoxPoints} // Array of points for the bounding box
                    color="blue"
                    lineWidth={0.4} // Adjust thickness of the line
                    raycast={noopRaycast}
                />
            )}
        </>
    );
});

export default SelectionPlane;
