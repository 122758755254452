import React, { useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import { observer } from 'mobx-react-lite';
import { floorplannerStore } from '../../store/floorplannerStore';
import DraggableObject from './DraggableObject';
import { SelectableSymbol, BoundingBox } from './SelectableSymbol';
import { SymbolType } from '../../types/wallTypes';

interface BackdropImageProps {
  symbol: SymbolType;
  imagePath: string;
  position: THREE.Vector2;
  size?: number;
  ratio?: number;
}

export const BackdropImage: React.FC<BackdropImageProps> = observer(({
  imagePath,
  position,
  size,
  ratio,
  symbol,
}) => {
  const [texture, setTexture] = useState<THREE.Texture | null>(null);
  const meshRef = useRef<THREE.Mesh>(null);
  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });
  const [isDragging, setIsDragging] = useState(false);

  const calculateBoundingBox = (): BoundingBox => {
    const halfWidth = dimensions.width / 2;
    const halfHeight = dimensions.height / 2;
    
    return {
      topLeft: [position.x - halfWidth, position.y + halfHeight],
      topRight: [position.x + halfWidth, position.y + halfHeight],
      bottomLeft: [position.x - halfWidth, position.y - halfHeight],
      bottomRight: [position.x + halfWidth, position.y - halfHeight],
      width: dimensions.width,
      height: dimensions.height,
      depth: 0
    };
  };

  const handleDragStart = (offset: [number, number]) => {
    setIsDragging(true);
  };

  const handleDrag = (newPosition: [number, number]) => {
    floorplannerStore.updateSymbolPosition(symbol.id, new THREE.Vector2(newPosition[0], newPosition[1]));
  };

  const handleDragEnd = (endPosition: [number, number]) => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (!imagePath) {
      console.error('No backdrop image path provided');
      return;
    }

    const loader = new THREE.TextureLoader();
    loader.load(
      imagePath,
      (loadedTexture) => {
        loadedTexture.minFilter = THREE.LinearFilter;
        loadedTexture.magFilter = THREE.LinearFilter;
        loadedTexture.needsUpdate = true;
        setTexture(loadedTexture);
      },
      undefined,
      (error) => {
        console.error('Error loading texture:', error);
      }
    );

    return () => {
      if (texture) {
        texture.dispose();
      }
    };
  }, [imagePath]);
  
  useEffect(() => {
    if (!imagePath) {
      console.error('No backdrop image path provided');
      return;
    }

    const image = new Image();
    image.src = imagePath;

    image.onload = () => {
      const originalWidth = image.width;
      const originalHeight = image.height;
      const aspectRatio = originalWidth / originalHeight;
      // 1. Convert size (mm) to scene units (1 unit = 1000 mm)
      const sceneUnitsForPortion = (size ?? 100) / 1000;

      // 2. Compute final planeWidth so that:
      //    (ratio * originalWidth px) => sceneUnitsForPortion
      //    => entire width => planeWidth = (sceneUnitsForPortion) / ratio
      const planeWidth = sceneUnitsForPortion / (ratio ?? 1);

      // 3. Maintain aspect ratio
      const planeHeight = planeWidth / aspectRatio;

      setDimensions({
        width: planeWidth,
        height: planeHeight,
      });

      const loader = new THREE.TextureLoader();
      loader.load(
        imagePath,
        (loadedTexture) => {
          loadedTexture.minFilter = THREE.LinearFilter;
          loadedTexture.magFilter = THREE.LinearFilter;
          loadedTexture.needsUpdate = true;
          setTexture(loadedTexture);
        },
        undefined,
        (error) => {
          console.error('Error loading texture:', error);
        }
      );
    };

    return () => {
      if (texture) {
        texture.dispose();
      }
    };
  }, [imagePath, ratio]);

  if (!texture) {
    return null;
  }

  return (
    <DraggableObject
      position={[position.x, position.y]}
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      selectable={true}
    >
      <SelectableSymbol
        handleSize={floorplannerStore.symbolHandleSize}
        calculateBoundingBox={calculateBoundingBox}
        onResize={() => {}}
        onResizeStart={() => {}}
        onResizeEnd={() => {}}
        showHandles={false}
        symbol={symbol}
      >
        <mesh 
          ref={meshRef}
          position={[0, 0, -0.01]} 
          renderOrder={-1000}
        >
          <planeGeometry args={[dimensions.width, dimensions.height]} />
          <meshBasicMaterial 
            map={texture} 
            transparent={true} 
            side={THREE.DoubleSide}
            depthTest={true}
            depthWrite={false}
          />
        </mesh>
      </SelectableSymbol>
    </DraggableObject>
  );
});
