import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import * as THREE from "three";
import { invalidate, useFrame, useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { CircleStairsType, zIndexEpsilon, RectStairsType, isRectStairsType } from "../../types/wallTypes";
import { SelectableSymbol, BoundingBox } from "./SelectableSymbol";
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import { Line } from "@react-three/drei";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";
import { set, transaction } from "mobx";

interface CircleStairsProps {
  circleStair: CircleStairsType;
  onDragStart: (circleStair: CircleStairsType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

interface SnapResult {
  point: THREE.Vector2;
  stairs: RectStairsType;
  distance: number;
  isBottomEdge: boolean;
}

const CircleStairs: React.FC<CircleStairsProps> = observer(({
  circleStair,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const [currentOpenAngle, setCurrentOpenAngle] = useState(circleStair.openAngle);
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const attachableRef = useRef<{ checkForAttachment: () => void }>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);
  const lineWeight = circleStair.lineWeight || floorplannerStore.symbolLineWeight;
  const circleStairsWidth = circleStair.circleStairsWidth;

  const grabHandleSize = 0.12;
  const handleRadius = useMemo(() => grabHandleSize / editorStore.zoomLevelDivisor(), [editorStore.zoomLevel]);
  const handleDepth = 0.02;

  const flipX = circleStair.flipHorizontal ? -1 : 1;
  const flipY = circleStair.flipVertical ? -1 : 1;
  const hingeOffsetX = useMemo(() => (flipX === -1 ? circleStairsWidth : 0), [circleStairsWidth, flipX]);
  const lineColor = circleStair.lineColor || floorplannerStore.lineColor;
  const stairStepSize = circleStair.stairStepSize || floorplannerStore.stairStepSize;

  const openAngleGrabHandleX = useMemo(
    () => hingeOffsetX + circleStairsWidth * 0.6 * Math.cos(currentOpenAngle) * flipX,
    [hingeOffsetX, circleStairsWidth, currentOpenAngle, flipX]
  );

  const openAngleGrabHandleY = useMemo(
    () => circleStairsWidth * 0.6 * Math.sin(currentOpenAngle) * flipY,
    [circleStairsWidth, currentOpenAngle, flipY]
  );

  const [hoveredHandle, setHoveredHandle] = useState<string | null>(null);
  const isHovered = (handle: string) => hoveredHandle === handle;
  const [prevCameraZoom, setPrevCameraZoom] = useState(camera.zoom);
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const noopRaycast = () => null;
  const aboutToModify = useRef(false);

  const SNAP_THRESHOLD = 0.5;  // General snap threshold
  const TOP_EDGE_SNAP_THRESHOLD = 0.2;  // Specific threshold for top edge
  const DETACH_THRESHOLD = 1.5;  // Threshold for detachment
  const OFFSET_DISTANCE = 0.5; // Distance to maintain between stairs when snapped
  const ANGLE_180_DEGREES = Math.PI;
  const ANGLE_TOLERANCE = 0.1;

  const findClosestRectStairs = useCallback((position: THREE.Vector2): SnapResult | null => {
    let minDistance = Infinity;
    let closestPoint: THREE.Vector2 | null = null;
    let closestStairs: RectStairsType | null = null;
    let isBottomEdge = false;

    floorplannerStore.symbolsMap.forEach((symbol) => {
      if (isRectStairsType(symbol) && symbol.id !== circleStair.id) {
        // Calculate bounds relative to the rect stairs center
        const bounds = {
          left: symbol.position.x - symbol.rectStairsWidth / 2,
          right: symbol.position.x + symbol.rectStairsWidth / 2,
          top: symbol.position.y + symbol.rectStairsHeight / 2,
          bottom: symbol.position.y - symbol.rectStairsHeight / 2
        };

        // Check if within horizontal range (with some margin)
        const horizontalDistance = Math.min(
          Math.abs(position.x - bounds.left),
          Math.abs(position.x - bounds.right)
        );

        if (horizontalDistance < SNAP_THRESHOLD) {
          // Calculate vertical distances to top and bottom edges
          const distanceToTop = Math.abs(position.y - bounds.top);
          const distanceToBottom = Math.abs(position.y - bounds.bottom);

          // Check top edge first with tighter threshold
          if (distanceToTop < SNAP_THRESHOLD && distanceToTop < minDistance) {
            minDistance = distanceToTop;
            // Snap to the exact center x position of rect stairs
            closestPoint = new THREE.Vector2(symbol.position.x, bounds.top);
            closestStairs = symbol;
            isBottomEdge = false;
          }

          // Check bottom edge with same threshold
          if (distanceToBottom < SNAP_THRESHOLD && distanceToBottom < minDistance) {
            minDistance = distanceToBottom;
            // Snap to the exact center x position of rect stairs
            closestPoint = new THREE.Vector2(symbol.position.x, bounds.bottom);
            closestStairs = symbol;
            isBottomEdge = true;
          }
        }
      }
    });

    return closestPoint && closestStairs ? {
      point: closestPoint,
      stairs: closestStairs,
      distance: minDistance,
      isBottomEdge
    } : null;
  }, [circleStair.id]);

  const handleAttachment = useCallback((point: THREE.Vector2, stairs: RectStairsType, isBottomEdge: boolean) => {
    // Match width to rect stairs first
    floorplannerStore.updateSymbolProperty(
      circleStair.id,
      "circleStairsWidth",
      stairs.rectStairsWidth
    );

    // Calculate snap position
    const snapPosition: [number, number] = [
      stairs.position.x,  // Always align centers horizontally
      isBottomEdge 
        ? stairs.position.y - (stairs.rectStairsHeight / 2)  // Bottom edge
        : stairs.position.y + (stairs.rectStairsHeight / 2)  // Top edge
    ];
    
    onDrag(snapPosition);

    // Calculate rotation
    const stairsRotation = stairs.rotation || 0;
    const newRotation = isBottomEdge ? stairsRotation + Math.PI : stairsRotation;

    // Update rotation
    floorplannerStore.updateSymbolProperty(
      circleStair.id,
      "rotation",
      newRotation
    );

    // Set attachment
    floorplannerStore.updateSymbolProperty(
      circleStair.id,
      "attachedTo",
      stairs.id
    );
  }, [circleStair.id, onDrag]);

  const handleDrag = useCallback((newPosition: [number, number]) => {
    const position = new THREE.Vector2(newPosition[0], newPosition[1]);
    const snapResult = findClosestRectStairs(position);
    const currentAttachment = circleStair.attachedTo;
    
    if (snapResult) {
      const { point, stairs, distance, isBottomEdge } = snapResult;
      
      if (currentAttachment) {
        // Handle detachment if beyond threshold
        if (distance > DETACH_THRESHOLD) {
          handleDetachment(newPosition);
        } else {
          // Stay attached and maintain position
          const snapPosition: [number, number] = [point.x, point.y];
          onDrag(snapPosition);
        }
      } else if (distance <= SNAP_THRESHOLD) {
        // Handle new attachment
        handleAttachment(point, stairs, isBottomEdge);
      } else {
        onDrag(newPosition);
      }
    } else {
      onDrag(newPosition);
      if (currentAttachment) {
        handleDetachment(newPosition);
      }
    }
  }, [onDrag, circleStair.id, circleStair.attachedTo, floorplannerStore]);

  const handleDetachment = useCallback((newPosition: [number, number]) => {
    onDrag(newPosition);
    floorplannerStore.updateSymbolProperty(
      circleStair.id,
      "attachedTo",
      undefined
    );
  }, [circleStair.id, floorplannerStore, onDrag]);

  const closestPointOnLineSegment = (
    point: THREE.Vector2,
    start: THREE.Vector2,
    end: THREE.Vector2
  ): THREE.Vector2 => {
    const line = end.clone().sub(start);
    const len = line.length();
    const lineDir = line.clone().divideScalar(len);
    
    const pointToStart = point.clone().sub(start);
    const t = pointToStart.dot(lineDir);
    
    if (t <= 0) return start.clone();
    if (t >= len) return end.clone();
    
    return start.clone().add(lineDir.multiplyScalar(t));
  };

  const calculateSnapPosition = (point: THREE.Vector2, stairs: RectStairsType): [number, number] => {
    // Calculate position with offset from the left edge
    const snapX = stairs.position.x - stairs.rectStairsWidth / 2 + OFFSET_DISTANCE;
    return [snapX, point.y];
  };

  const handleRotation = useCallback((newRotation: number) => {
    // Update circular stairs rotation
    floorplannerStore.updateSymbolProperty(
      circleStair.id,
      "rotation",
      newRotation
    );

    // If attached, also rotate rectangular stairs
    if (circleStair.attachedTo) {
      const attachedStairs = floorplannerStore.symbolsMap.get(circleStair.attachedTo);
      if (attachedStairs && isRectStairsType(attachedStairs)) {
        floorplannerStore.updateSymbolProperty(
          attachedStairs.id,
          "rotation",
          newRotation
        );
      }
    }
  }, [circleStair.id, circleStair.attachedTo, floorplannerStore]);

  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) return { topLeft: [0, 0], topRight: [0, 0], bottomLeft: [0, 0], bottomRight: [0, 0], width: 0, height: 0, depth: 0 };

    const tempGroup = groupRef.current.clone();
    const originalRotation = tempGroup.rotation.clone();
    tempGroup.rotation.set(0, 0, 0);

    const boundingBox = new THREE.Box3().setFromObject(tempGroup);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);
    tempGroup.rotation.copy(originalRotation);

    const topLeft = new THREE.Vector3(boundingBox.min.x, boundingBox.max.y, 0);
    const topRight = new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, 0);
    const bottomLeft = new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, 0);
    const bottomRight = new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, 0);

    return {
      topLeft: [topLeft.x, topLeft.y],
      topRight: [topRight.x, topRight.y],
      bottomLeft: [bottomLeft.x, bottomLeft.y],
      bottomRight: [bottomRight.x, bottomRight.y],
      width: size.x,
      height: size.y,
      depth: size.z,
    };
  }, [circleStair.position.x, circleStair.position.y, circleStairsWidth, currentOpenAngle]);

  const dragOffset = useRef({ x: 0, y: 0 });

  const onHandleDrag = useCallback((event: PointerEvent) => {
    if (aboutToModify.current) {
      floorplannerStore.pushToUndoStack();
      aboutToModify.current = false;
    }
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dx = worldX - circleStair.position.x - dragOffset.current.x;
    const dy = worldY - circleStair.position.y - dragOffset.current.y;
    let angle = Math.atan2(dy * flipY, dx * flipX);
    angle = angle < 0 ? angle + 2 * Math.PI : angle;

    const snapAngle = (angle: number, bounds: [number, number], snapTo: number) => {
      return angle >= bounds[0] && angle <= bounds[1] ? snapTo : angle;
    };

    angle = snapAngle(angle, [(75 * Math.PI) / 180, (105 * Math.PI) / 180], Math.PI / 2);
    angle = snapAngle(angle, [(165 * Math.PI) / 180, (195 * Math.PI) / 180], Math.PI);
    angle = snapAngle(angle, [(255 * Math.PI) / 180, (285 * Math.PI) / 180], (270 * Math.PI) / 180);
    angle = snapAngle(angle, [(345 * Math.PI) / 180, Math.PI * 2], Math.PI * 2);

    setCurrentOpenAngle(angle);
    floorplannerStore.updateSymbolProperty(circleStair.id, "openAngle", angle);

  }, [circleStair.position.x, circleStair.position.y, flipX, flipY, gl, camera, floorplannerStore, circleStair.id]);

  const onPointerDownHandle = useCallback((event: React.PointerEvent) => {
    event.stopPropagation();
    aboutToModify.current = true;
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);

    dragOffset.current = {
      x: worldX - (circleStairsWidth * 0.75 * Math.cos(currentOpenAngle) * flipX + circleStair.position.x),
      y: worldY - (circleStairsWidth * 0.75 * Math.sin(currentOpenAngle) * flipY + circleStair.position.y),
    };

    gl.domElement.addEventListener("pointermove", onHandleDrag);
    gl.domElement.addEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(true);
  }, [currentOpenAngle, circleStair.position.x, circleStair.position.y, circleStairsWidth, flipX, flipY, gl, onHandleDrag, floorplannerStore]);

  const onPointerUpHandle = useCallback(() => {
    aboutToModify.current = false;
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
    calculateBoundingBox();
  }, [gl, onHandleDrag, floorplannerStore]);

  const generateArcPointsAndLines = useMemo(() => {
    const arcPoints = [];
    const extraLines = [];
    const maxDistance = Math.max(stairStepSize, 0.01);
    let previousPoint = null;
    let angle = 0;
    let lastLinePoint = null;
    let maxIterations = 10000;

    while (angle <= currentOpenAngle && maxIterations > 0) {
      maxIterations--;
      const currentPoint = new THREE.Vector3(
        circleStairsWidth * Math.cos(angle) * flipX,
        circleStairsWidth * Math.sin(angle) * flipY,
        0
      );

      if (previousPoint) {
        const distance = currentPoint.distanceTo(previousPoint);

        if (distance > maxDistance) {
          angle -= (distance - maxDistance) / circleStairsWidth;
          continue;
        }
      }

      arcPoints.push(currentPoint);

      if (!lastLinePoint || currentPoint.distanceTo(lastLinePoint) > maxDistance) {
        extraLines.push({
          x: currentPoint.x,
          y: currentPoint.y,
          rotation: angle,
        });
        lastLinePoint = currentPoint.clone();
      }

      previousPoint = currentPoint;
      angle += 0.01;
    }

    return { arcPoints, extraLines };
  }, [circleStairsWidth, stairStepSize, currentOpenAngle, flipX, flipY]);

  const shapePoints = useMemo(() => 
    generateArcPointsAndLines.arcPoints.map(point => new THREE.Vector2(point.x, point.y))
  , [generateArcPointsAndLines.arcPoints]);

  const shape = useMemo(() => {
    const newShape = new THREE.Shape();
    newShape.moveTo(0, 0);
    shapePoints.forEach((point) => {
      newShape.lineTo(point.x, point.y);
    });
    newShape.lineTo(0, 0);
    return newShape;
  }, [shapePoints]);

  useFrame(() => {
    if (camera.zoom !== prevCameraZoom) {
      setPrevCameraZoom(camera.zoom);
      invalidate(); // Trigger a re-render
    }
  });

  useEffect(() => {
    setCurrentOpenAngle(circleStair.openAngle);
  }, [circleStair.openAngle]);

  useEffect(() => {
    editorStore.updateGroupRef(circleStair.id, groupRef.current);
  }, [circleStair.id, floorplannerStore]);

  useEffect(() => {
    if (circleStair.attachedTo) {
      const attachedStairs = floorplannerStore.symbolsMap.get(circleStair.attachedTo);
      if (attachedStairs && isRectStairsType(attachedStairs)) {
        // Calculate new snap position based on rectangular stairs position
        const bounds = {
          left: attachedStairs.position.x - attachedStairs.rectStairsWidth / 2,
          right: attachedStairs.position.x + attachedStairs.rectStairsWidth / 2,
          top: attachedStairs.position.y + attachedStairs.rectStairsHeight / 2,
          bottom: attachedStairs.position.y - attachedStairs.rectStairsHeight / 2
        };

        // Determine if snapped to top or bottom edge
        const distanceToTop = Math.abs(circleStair.position.y - bounds.top);
        const distanceToBottom = Math.abs(circleStair.position.y - bounds.bottom);
        const isBottomEdge = distanceToBottom < distanceToTop;

        // Update circular stairs position
        const newPosition = new THREE.Vector2(
          circleStair.position.x,
          isBottomEdge ? bounds.bottom : bounds.top
        );

        // Update position while maintaining x-coordinate
        floorplannerStore.updateSymbolProperty(
          circleStair.id,
          "position",
          [newPosition.x, newPosition.y]
        );

        // Update rotation to match rectangular stairs
        const is180Degrees = Math.abs(circleStair.openAngle - ANGLE_180_DEGREES) < ANGLE_TOLERANCE;
        const newRotation = is180Degrees && isBottomEdge 
          ? (attachedStairs.rotation || 0) + ANGLE_180_DEGREES 
          : (attachedStairs.rotation || 0);

        floorplannerStore.updateSymbolProperty(
          circleStair.id,
          "rotation",
          newRotation
        );
      }
    }
  }, [
    circleStair.attachedTo,
    circleStair.id,
    circleStair.position.x,
    circleStair.position.y,
    circleStair.openAngle,
    floorplannerStore
  ]);

  return (
    <group
      position={[0, 0, (circleStair.zIndex * zIndexEpsilon)]}
    >
      <DraggableObject
        position={[circleStair.position.x, circleStair.position.y]}
        onDragStart={(offset) => {
          setIsDragging(true);
          onDragStart(circleStair, offset)
        }}
        onDragEnd={(endPosition) => {
          onDragEnd(endPosition)
          setIsDragging(false);
        }}
        onDrag={handleDrag}
        selectable={true}
        attachmentId={circleStair.id}
        attachmentType="doorAttachments"
        symbol={circleStair}
      >
        <SelectableSymbol
          ref={groupRef}
          handleSize={floorplannerStore.symbolHandleSize}
          calculateBoundingBox={calculateBoundingBox}
          onResizeStart={() => setIsResizing(true)}
          onResize={(newWidth, newHeight, boundingBox, handle) => {
            transaction(() => {
              // Handle rotation
              if (handle === "rotate") {
                const angle = Math.atan2(
                  boundingBox.topRight[1] - boundingBox.bottomRight[1],
                  boundingBox.topRight[0] - boundingBox.bottomRight[0]
                );
                handleRotation(angle);
                return;
              }

              // Handle resizing
              const widthDiff = newWidth - circleStair.circleStairsWidth;
              floorplannerStore.updateSymbolProperty(circleStair.id, "circleStairsWidth", newWidth);
              const position = [
                (handle === "topLeft" || handle === "bottomLeft") ? circleStair.position.x - widthDiff * Math.cos(circleStair.rotation || 0) : circleStair.position.x,
                (handle === "topLeft" || handle === "bottomLeft") ? circleStair.position.y - widthDiff * Math.sin(circleStair.rotation || 0) : circleStair.position.y,
              ] as [number, number];
              floorplannerStore.updateSymbolProperty(circleStair.id, "position", position);
            });
          }}
          isDragging={isDragging}
          onResizeEnd={() => setIsResizing(false)}
          center={[circleStairsWidth / 2, circleStairsWidth / 2]}
          rotation={circleStair.rotation}
          symbol={circleStair}
          onPointerOver={() => setHoveredHandle("circleStair")}
          onPointerOut={() => setHoveredHandle(null)}
        >
          <group
            ref={groupRef}
            rotation={[0, 0, circleStair.rotation || 0]}
            onPointerEnter={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
            }}
            onPointerLeave={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
            }}
          >
            {/* Invisible box to catch events on any unfilled areas */}
            <mesh
              position={[
                circleStairsWidth / 2,
                circleStairsWidth / 2,
                -0.03
              ]}
              rotation={[0, 0, 0]}
              onPointerEnter={() => {
                if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
              }}
              onPointerLeave={() => {
                if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
              }}
            >
              <boxGeometry args={[circleStairsWidth, circleStairsWidth, 0.06]} />
              <meshBasicMaterial
                //color="white"
                transparent
                opacity={0.0}
              />
            </mesh>
            <Line
              points={generateArcPointsAndLines.arcPoints.map((point) => [point.x, point.y, point.z])}
              color={hoveredHandle === "circleStair" || circleStair.selected ? "blue" : lineColor}
              lineWidth={lineWeight}
              {...(circleStair.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />

            {generateArcPointsAndLines.extraLines.map((line, index) => (
              <Line
                key={index}
                points={[
                  [0, 0, 0],
                  [line.x, line.y, 0],
                ]}
                color={hoveredHandle === "circleStair" || circleStair.selected ? "blue" : lineColor}
                lineWidth={lineWeight}
                {...(circleStair.lineType === "dashed" && {
                  dashed: true,
                  dashSize: 0.15,
                  gapSize: 0.1,
                })}
                raycast={noopRaycast}
              />
            ))}

            <Line
              points={[
                [0, 0, 0],
                [circleStairsWidth * Math.cos(currentOpenAngle) * flipX, circleStairsWidth * Math.sin(currentOpenAngle) * flipY, 0],
              ]}
              color={hoveredHandle === "circleStair" || circleStair.selected ? "blue" : lineColor}
              lineWidth={lineWeight}
              {...(circleStair.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />

            {(circleStair.selected && !isResizing && !isDragging && editorStore.selections.length === 1) && (
              <group
                onPointerDown={onPointerDownHandle as unknown as (event: THREE.Event) => void}
                onPointerUp={onPointerUpHandle as unknown as (event: THREE.Event) => void}
                onPointerEnter={(event) => {
                  event.stopPropagation();
                  document.body.classList.add("cursor-arc");
                  setHoveredHandle("circleStairHandle");
                }}
                onPointerLeave={(event) => {
                  event.stopPropagation();
                  document.body.classList.remove("cursor-arc");
                  setHoveredHandle(null);
                }}
                position={[openAngleGrabHandleX, openAngleGrabHandleY, 0]}
              >
                <mesh>
                  <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                  <meshBasicMaterial color="blue" />
                </mesh>
                <mesh position={[0, 0, handleDepth / 2]} rotation={[Math.PI / 2, 0, 0]}>
                  <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                  <meshBasicMaterial color={isHovered("circleStairHandle") ? "blue" : "white"} />
                </mesh>
              </group>
            )}
          </group>
        </SelectableSymbol>
      </DraggableObject>
    </group>
  );
});

export default CircleStairs;
